export const TOKEN_KEY = 'tokenKey';
export const NOGUI_KEY = 'nogui';
export const PARENT_KEY = 'parent';
export const AUTOCLOSE_KEY = 'autoclose';
export const REDIRECT_KEY = 'redirect';
export const TOKEN_HEADER_KEY = 'Authorization';

export const FAILED_ACTION_MESSAGE = (action: string) => `Sorry, we couldn't ${action}`;

export const ISBN_KEY = 'isbn';

// Vault communication:
export const REDIRECT_TO = 'redirectTo';
export const VAULT_ROUTE_PD_ASSIGNMENT = '/pdAssignmentUI';
export const REDIRECT_TO_VAULT = 'redirectToVault';
export const REDIRECT_TO_VAULT_FOR_AUTH = 'createSPAAuthPopup';
export const LOCK_PAGE = 'lockPage'; // We have to lock vault if we work with forms and there are some changes which should be approved by user

export const PROD_HOSTNAME = 'vault-spa-ui.prod.vault.wiley.host';
