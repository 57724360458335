import React, { FC, useRef, useEffect, useLayoutEffect, useState, SyntheticEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'src/App';
import useWindowSize from 'src/utils/hooks/useWindowSize';
import Tooltip from '../Tooltip';
import ModalCloseButton from './ModalCloseButton';

export interface IHeaderClasses {
  header?: string,
  title?: string,
  closeButton?: string,
}

interface ModalHeaderInterface {
  title: string;
  classes: IHeaderClasses;
  onClose(event: SyntheticEvent<{}, Event>): void;
}

const useStyles = makeStyles((theme: ITheme) => ({
  header: {
    display: 'flex',
    minHeight: '5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.typical.white,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    ...theme.typography.h5,
  },
}));

const ModalHeader: FC<ModalHeaderInterface> = ({
  title,
  onClose,
  classes: overrideClasses = {},
}) => {
  const classes = useStyles({ classes: overrideClasses });
  const [tooltipShow, setTooltipShow] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const windowSize = useWindowSize();
  useLayoutEffect(() => {
    if (headerRef.current && titleRef.current && closeBtnRef.current) {
      const headerStyles = window.getComputedStyle(headerRef.current);
      const closeBtnStyles = window.getComputedStyle(closeBtnRef.current);

      const titleWidth = parseFloat(headerStyles.width)
      - (parseFloat(headerStyles.paddingLeft) + parseFloat(headerStyles.paddingRight))
      - parseFloat(closeBtnStyles.width);
      titleRef.current.style.width = `${titleWidth}px`;
    }
  }, [titleRef, headerRef, closeBtnRef, windowSize[0]]);

  useEffect(() => {
    setTooltipShow((titleRef?.current?.offsetWidth || 0) < (titleRef?.current?.scrollWidth || 0));
  }, [title, windowSize[0]]);

  return (
    <div className={classes.header} ref={headerRef}>
      <Tooltip title={tooltipShow ? title : ''} placement="bottom-start">
        <div
          className={classes.title}
          ref={titleRef}
          data-seleniumid="modal-title"
        >
          {title}
        </div>
      </Tooltip>
      <ModalCloseButton
        ref={closeBtnRef}
        onClose={onClose}
      />
    </div>
  );
};

export default ModalHeader;
