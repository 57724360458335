import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TextareaAutosize, Box } from '@material-ui/core';

const TITLE = 'Product ISBN(s)';
const PLACEHOLDER = '13-Digit ISBN(s)';

interface IISBNs {
  value:string,
  onChange:(value:string) => void;
}

const useStyles = makeStyles(theme => ({
  textArea: {
    marginTop: theme.spacing(2),
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '1.5rem',
    width: 260,
    maxWidth: 300,
  },
}));

const ISBNs = ({ value, onChange }: IISBNs) => {
  const { textArea } = useStyles();

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {TITLE}
      </Typography>
      <TextareaAutosize
        className={textArea}
        rowsMin={20}
        rowsMax={30}
        value={value}
        placeholder={PLACEHOLDER}
        data-seleniumid="isbns-input"
        onChange={(e) => {
          onChange(e.target.value || '');
        }}
      />
    </Box>
  );
};

export default memo(ISBNs);
