export type PrinterAssignmentType = 'POD' | 'TRADITIONAL';

export interface PrinterAssignment {
  name: string;
  type: PrinterAssignmentType;
  podType?: string;
  channel?: string;
  state: boolean;
}

export type PrintersAssignmentResponse = {
  printers: PrinterAssignment[];
  [key: string]: any;
}

export const LOAD_PRINTERS_ASSIGNMENTS = 'LOAD_PRINTERS_ASSIGNMENTS';
export const LOAD_PRINTERS_ASSIGNMENTS_SUCCESS = 'LOAD_PRINTERS_ASSIGNMENTS_SUCCESS';
export const LOAD_PRINTERS_ASSIGNMENTS_FAILURE = 'LOAD_PRINTERS_ASSIGNMENTS_FAILURE';

export type PrintersAssignment = PrinterAssignment[];
