import { FAILED_ACTION_MESSAGE } from 'src/constants';
import { ErrorResponse } from 'src/services/api';
import { toastrErrorWithoutAuth } from 'src/utils';
import { getPrinters as asyncCall } from 'src/redux/api';
import { toastr } from 'react-redux-toastr';
import { LOAD_PRINTERS, LOAD_PRINTERS_SUCCESS, Printers } from './types';

export const loadPrinters = () => ({
  type: LOAD_PRINTERS,
  transformError: (e: ErrorResponse) => {
    toastrErrorWithoutAuth(e, FAILED_ACTION_MESSAGE('load printers'));
    return e;
  },
  transformResult: (res: any) => {
    const data = Array.isArray(res) ? res : [];
    if (!data.length) {
      toastr.error('Failed', 'There is no printers');
    }
    return data;
  },
  asyncCall,
} as const);

export const setPrinters = (printers: Printers) => ({
  type: LOAD_PRINTERS_SUCCESS,
  payload: printers,
} as const);
