import { RootState } from 'src/redux/store';
import { InferActionTypes } from 'src/redux/utils';
import * as actions from './actions';
import {
  LOAD_DELIVERY_EVENTS_SUCCESS,
  BYPASS_EVENT_SUCCESS,
  DeliveryEvent,
} from './types';

export * from './actions';
export * from './types';

export const initialState: DeliveryEvent[] = [];

export type ActionTypes = InferActionTypes<typeof actions>;

export default (
  state = initialState,
  action: ActionTypes,
): typeof initialState => {
  switch (action.type) {
    case LOAD_DELIVERY_EVENTS_SUCCESS:
      return action.payload;
    case BYPASS_EVENT_SUCCESS: {
      const { id } = action.payload;
      const index = state.findIndex(el => el.id === id);
      return Object.assign([], state, { [index]: action.payload });
    }
    default: return state;
  }
};

export const getDeliveryEvents = (state: RootState) => state.deliveryEvents || [];
