
import { RootState } from 'src/redux/store';

export const getRequestInfo = (url: string) => (
  state: RootState,
) => (
  state.network && url in state.network
    ? state.network[url]
    : null
);
