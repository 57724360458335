import React, { useState, useEffect, SyntheticEvent } from 'react';
import cn from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener, Button } from '@material-ui/core';
import { FilterList, ArrowDownward, ArrowUpward } from '@material-ui/icons';


const DEF_SORT_TITLE = 'Toggle SortBy';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  header: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...theme.typography.caption,
    fontWeight: theme.typography.fontWeightBold,
  },
  sortIcon: {
    width: 20,
    height: 20,
    color: theme.palette.secondary.light,
  },
  filterRoot: {
    position: 'relative',
  },
  filterButton: {
    padding: 0,
    minWidth: 25,
  },
  filterIcon: {
    width: 20,
    height: 20,
    color: theme.palette.grey[700],
    '&.active': {
      color: theme.palette.secondary.light,
    },
  },
  filterWrap: {
    minWidth: 120,
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[8],
  },
}));

type SortIcon = {
  className: string;
  isSortedDesc?: boolean;
}

const SortIcon = ({ className, isSortedDesc }: SortIcon) => (isSortedDesc
  ? <ArrowDownward className={className} />
  : <ArrowUpward className={className} />);

const HeaderCell = ({ column, filterable, sortable }:any) => {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles();
  const { filterValue, canFilter, isSorted, canSort, isSortedDesc, Header } = column;

  const onClose = () => setOpen(false);
  const onOpen = (event: SyntheticEvent<{}, Event>) => {
    event.stopPropagation();
    setOpen(!open);
  };

  useEffect(() => {
    if (!filterable) return;
    if (open && column.Filter?.name?.startsWith('Select')) {
      onClose();
    }
  }, [filterValue]);

  const isColumnSortable = sortable && canSort;
  const sortTitle = typeof Header === 'string' ? `Toggle sort by ${Header}` : DEF_SORT_TITLE;

  return (
    <div className={classes.root}>
      <Tooltip title={sortTitle} disableHoverListener={!isColumnSortable}>
        <div className={classes.header}>
          {column.render('Header')}
          {(isColumnSortable && isSorted) ? <SortIcon isSortedDesc={isSortedDesc} className={classes.sortIcon} /> : null}
        </div>
      </Tooltip>
      {filterable && canFilter && (
        <ClickAwayListener onClickAway={onClose}>
          <div
            className={classes.filterRoot}
            onClick={(event: SyntheticEvent<{}, Event>) => {
              event.stopPropagation();
            }}
          >
            <Button
              className={classes.filterButton}
              onClick={onOpen}
              data-seleniumid="filter-button"
            >
              <FilterList className={cn(classes.filterIcon, { active: !!filterValue })} />
            </Button>
            {open && (
              <div className={classes.filterWrap}>
                {column.render('Filter', { onClear: onClose })}
              </div>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default HeaderCell;
