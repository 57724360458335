import { FunctionComponent } from 'react';
import { RouteProps, RouteComponentProps } from 'react-router';
import NotFound from 'src/pages/NotFound';
import Assignments from 'src/pages/Assignments';
import AssignmentsStatus from 'src/pages/AssignmentsStatus';
import DeliveryEvents from 'src/pages/DeliveryEvents';
import { Auth } from 'src/msal/AuthProvider';
import paths from './paths';

export interface IRouteProps extends RouteProps, Auth {
  isPrivate?: boolean;
  component: FunctionComponent<RouteComponentProps<{}>>;
  path: string;
}

export const routes: { [key: string]: IRouteProps } = {
  ASSIGNMENTS: {
    isPrivate: true,
    path: paths.assignments,
    component: Assignments,
  },
  ASSIGNMENTS_STATUS: {
    isPrivate: true,
    path: paths.assignmentsStatus,
    component: AssignmentsStatus,
  },
  DELIVERY_EVENTS: {
    isPrivate: true,
    path: paths.deliveryEvents,
    component: DeliveryEvents,
  },
  NOT_FOUND: {
    path: '*',
    component: NotFound,
  },
};
