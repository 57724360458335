import { UserAgentApplication } from 'msal';
import ls from 'src/services/localStorage';
import { isProd } from 'src/utils';

const TOKEN_LS_KEY = 'msal.idtoken';
const MSAL_PRIORITY = 'useMSAL';


function getPayload(token: string | null) {
  if (token != null) {
    // eslint-disable-next-line no-console
    console.log('token is not null:', token);
    try {
      return JSON.parse(atob(token.split('.')[1]));
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error decoding or parsing token:', error);
    }
  }
  return null;
}

const isTokenExpired = (token: string | null): boolean => {
  const payload = getPayload(token);
  if (payload != null) {
    const expiration = new Date(payload.exp * 1000);
    return expiration.valueOf() < Date.now();
  }
  return false;
};

// TODO use accessToken!
export const getMSALToken = async (): Promise<string | null> => {
  let tokenFromLs = ls.get(TOKEN_LS_KEY);
  if (isTokenExpired(tokenFromLs)) {
    ls.clear();
    // eslint-disable-next-line no-use-before-define
    await msalApp.acquireTokenRedirect({
      scopes: ['user.read'],
    });
    tokenFromLs = ls.get(TOKEN_LS_KEY);
  }
  return tokenFromLs;
};

export const useMSAL = () => ls.setJSON(MSAL_PRIORITY, true);
// export const isMSALPriority = () => true;
export const isMSALPriority = () => ls.getJSON(MSAL_PRIORITY);

const creds = {
  // only dev env supports localhost urls for work with azure
  // https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/appId/ce48b9ed-af14-4c79-b95a-64b80cf8133e/isMSAApp/
  dev: {
    clientId: 'ce48b9ed-af14-4c79-b95a-64b80cf8133e',
    authority: 'https://login.microsoftonline.com/b44c78d5-3982-4579-8837-38fa05d9e3cb',
  },
  qa: {
    clientId: '0c37de51-639f-4d77-912b-98c28f83c3cf',
    authority: 'https://login.microsoftonline.com/b44c78d5-3982-4579-8837-38fa05d9e3cb',
  },
  uat: {
    clientId: '52194c9f-1bf6-4756-af84-0ff2a1ac95f2',
    authority: 'https://login.microsoftonline.com/b44c78d5-3982-4579-8837-38fa05d9e3cb',
  },
  perf: {
    clientId: 'a99d3bff-8012-4c5d-b0ec-e79360026515',
    authority: 'https://login.microsoftonline.com/b44c78d5-3982-4579-8837-38fa05d9e3cb',
  },
  prod: {
    clientId: 'dc65072d-dabc-4de1-95a8-404a539d0fdd',
    authority: 'https://login.microsoftonline.com/24fe244f-890e-46ef-be2f-a5202976b7a5',
  },
};

const getCreds = () => {
  const hostName = window.location.hostname;
  const { REACT_APP_ENV } = process.env;

  if (isProd()) {
    // eslint-disable-next-line no-console
    console.log('work on: prod');
    return creds.prod;
  }

  const env = REACT_APP_ENV || Object.keys(creds).find(env => hostName.includes(`.${env}`)) || 'dev';
  // eslint-disable-next-line no-console
  console.log('work on: ', env);

  // @ts-ignore
  return creds[env];
};

export const msalApp = new UserAgentApplication({
  auth: {
    ...getCreds(),
    redirectUri: window.location.origin,
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});
