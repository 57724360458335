import { Row } from 'src/components/Table/types';

export interface DeliveryEvent extends Row {
  deliveryEventId?: string;
  userName: string;
  printVendor?: string;
  deliveryChannel?: string;
  status: string;
  eventType: string;
  eventName: string;
  eventStartTime: string;
  eventEndTime?: string;
  bypass?: boolean;
  errorMessage?: string;

  bypassDisabled?: boolean;
}

export type DeliveryEventResponse = Omit<DeliveryEvent, 'id, bypassDisabled'>

export const LOAD_DELIVERY_EVENTS = 'LOAD_DELIVERY_EVENTS';
export const LOAD_DELIVERY_EVENTS_SUCCESS = 'LOAD_DELIVERY_EVENTS_SUCCESS';
export const LOAD_DELIVERY_EVENTS_FAILURE = 'LOAD_DELIVERY_EVENTS_FAILURE';
export const BYPASS_EVENT = 'BYPASS_EVENT';
export const BYPASS_EVENT_SUCCESS = 'BYPASS_EVENT_SUCCESS';
