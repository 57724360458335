import React from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { byPassEvent } from 'src/redux/reducers/deliveryEvents';
import { makeStyles, Typography } from '@material-ui/core';
import { ITheme } from 'src/App';

const useStyles = makeStyles((theme: ITheme) => ({
  link: {
    cursor: 'pointer',
    '&.disabled': {
      color: theme.palette.black.disabled,
      cursor: 'not-allowed',
    },
  },
}));

const BypassCell = (props: any) => {
  const { value, row: { original } } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { bypassDisabled, id } = original;

  if (!id || typeof value !== 'boolean') {
    return null;
  }

  const onHandle = () => {
    dispatch(byPassEvent({
      ...original,
      bypassDisabled: false,
      eventEndTime: '',
    }));
  };

  const disabled =  (typeof bypassDisabled === 'boolean' && !bypassDisabled) || !value;

  return (
    <Typography
      variant="caption"
      color="primary"
      className={cn(classes.link, { disabled })}
      onClick={onHandle}
    >
      BYPASS
    </Typography>
  );
};

export default BypassCell;
