// TODO resolve ts compiling problem with src/mock
// import { Printer } from 'src/mock/db/DBSchema';
export type Printer = {
  id: string;
  printerName: string;
  channelName: string;
  podType: string;
  podTypeId: string;
  printerTypeName: string;
  groupId: string;
  printerAndChannelName: string;
}

export const LOAD_PRINTERS = 'LOAD_PRINTERS';
export const LOAD_PRINTERS_SUCCESS = 'LOAD_PRINTERS_SUCCESS';
export const LOAD_PRINTERS_FAILURE = 'LOAD_PRINTERS_FAILURE';

// export type { Printer };
export type Printers = Printer[];
