import React, { FunctionComponent } from 'react';
import PaperLayout from 'src/components/layout/PaperLayout';
import { makeStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import { IRouteProps } from 'src/router/routes';
import Permission from './Permission';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.grey['100'],
    padding: theme.spacing(3),
  },
}));

const PrivateRoute: FunctionComponent<IRouteProps> = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PaperLayout>
        <Permission
          isAuthenticated={isAuthenticated}
        >
          <Route
            {...rest}
            render={props =>
              <Component {...props} />
            }
          />
        </Permission>
      </PaperLayout>
    </div>
  );
};

PrivateRoute.defaultProps = {
  path: '/',
};

export default PrivateRoute;
