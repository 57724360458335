import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface IInfoView {
  seleniumId: string,
  title: string,
  text?: string,
  classes?: object,
}

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
}));

const InfoView: FC<IInfoView> = ({ seleniumId, title, text, classes = {} }) => {
  const viewClasses = useStyles({ classes });
  return (
    <div className={viewClasses.wrap}>
      <Typography variant="h4" data-seleniumid={`${seleniumId}-title`} className={viewClasses.title}>
        {title}
      </Typography>
      {text && (
        <Typography variant="body1" data-seleniumid={`${seleniumId}-title`} className={viewClasses.text}>
          {text}
        </Typography>
      )}
    </div>
  );
};
export default InfoView;
