import React, { FC } from 'react';
import { Auth } from 'src/msal/AuthProvider';
import { Typography } from '@material-ui/core';

interface IPermission extends Auth{
  children: JSX.Element,
}

const WAIT_MSG = 'Please wait while we redirect you to log in page';

const Permission: FC<IPermission> = ({ children, isAuthenticated }) => {
  if (!isAuthenticated) {
    return (
      <Typography variant="h6" align="center">
        {WAIT_MSG}
      </Typography>
    );
  }

  return children;
};

export default Permission;
