import axios from 'axios';
import qs from 'query-string';

export const REQUEST_PREFIX = '/api/v2';

const instance = axios.create({
  baseURL: process.env.ROOT_API_URL || REQUEST_PREFIX,
  responseType: 'json',
  withCredentials: true,
  paramsSerializer: params => qs.stringify(params),
});

export default instance;
