import { createStore, compose, applyMiddleware, combineReducers, AnyAction } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import network from 'src/redux/reducers/network';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { createBrowserHistory } from 'history';
import rootSaga from 'src/redux/sagas';
import asyncMiddleware from './asyncMiddleware';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import reducers from '.';

type WindowWithDevtoolsExtension = Window & { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any };
const isDevtoolsExtensionPresent = (arg: Window | WindowWithDevtoolsExtension): arg is WindowWithDevtoolsExtension => '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in arg;
const composeEnhancer = isDevtoolsExtensionPresent(window) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const history = createBrowserHistory();

const appReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
  network,
  toastr: toastrReducer,
});

export type RootState = ReturnType<typeof appReducer>;

// eslint-disable-next-line arrow-body-style
const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  // handle: clean store
  return appReducer(state, action);
};

export const sagaMiddleware = createSagaMiddleware();
const middleware = [
  routerMiddleware(history),
  asyncMiddleware({
    defaultTransformError: (e:any) => (typeof e === 'string' ? e : e?.response?.data?.message || ''),
  }),
  sagaMiddleware,
];

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['network', 'toastr', 'router', 'printersAssignments', 'printers', 'deliveryEvents'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  return { store, persistor: persistStore(store) };
};
