import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import Table, { DEFAULT_CELL_HEIGHT, DEFAULT_HEADER_HEIGHT } from 'src/components/Table';
import { Vendor } from '../common/tablesConfiguration';

interface IVendorsSection {
  items: Vendor[];
  columns: any[];
  title: string;
  seleniumID: string;
  isLoading: boolean;
}

const useStyles = makeStyles(theme => ({
  content: {
    minWidth: 200,
  },
}));

const useTitleStyle = makeStyles(theme => ({
  root: {
    minHeight: 40,
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

const VendorsSection = ({ items, columns, title, seleniumID, isLoading }: IVendorsSection) => {
  const classes = useStyles();
  const titleClasses = useTitleStyle();

  const minHeight = (DEFAULT_HEADER_HEIGHT + 1) + DEFAULT_CELL_HEIGHT * 2;
  const maxHeight = (DEFAULT_HEADER_HEIGHT + 1) + DEFAULT_CELL_HEIGHT * 3;

  return (
    <div className={classes.content}>
      <Typography
        variant="subtitle2"
        data-seleniumid={`${seleniumID}-title`}
        classes={titleClasses}
      >
        {title}
      </Typography>
      <Box display="flex" maxHeight={maxHeight} minHeight={minHeight}>
        <Table
          isLoading={isLoading}
          seleniumId={seleniumID}
          data={items}
          columns={columns}
        />
      </Box>
    </div>
  );
};

export default memo(VendorsSection);
