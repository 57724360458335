import { PrintersAssignment, PrinterAssignment, PrinterAssignmentType } from 'src/redux/reducers/printersAssignments';
import { Row } from 'src/components/Table/types';


export interface Vendor extends Row {
  name: string;
  type: PrinterAssignmentType;
}

export const columns = [
  {
    accessor: 'name',
    Header: 'Print Vendor',
  },
];

const getTitle = ({ type, name, channel, ...rest } : PrinterAssignment) => {
  if (type === 'POD') {
    return channel ? `${name} - ${channel}` : name;
  }
  return name;
};

export const createVendorsRows = (entities: PrintersAssignment): Vendor[] => entities.map(entity => {
  const name = getTitle(entity);
  return {
    id: name.toLowerCase().replace(/(-|\s)+/g, '-'),
    name,
    type: entity.type,
  };
});
