import ls from 'src/services/localStorage';
import qs, { ParsedQuery } from 'query-string';
import { TOKEN_KEY, NOGUI_KEY, PARENT_KEY, AUTOCLOSE_KEY, REDIRECT_KEY } from '../constants';

const hasParent = () => ls.getJSON(PARENT_KEY);
const isAutoClose = () => ls.getJSON(AUTOCLOSE_KEY);
export const isGUI = () => !ls.getJSON(NOGUI_KEY);
export const getRedirectURL = () => ls.get(REDIRECT_KEY);
export const getParentToken = () => ls.get(TOKEN_KEY);
export const clearParentToken = () => ls.remove(TOKEN_KEY);
export const clearAutoClose = () => ls.remove(AUTOCLOSE_KEY);

export const isSPAMode = () => !!(!hasParent() && !getParentToken() && !isAutoClose());
export const isIframeMode = () => !!hasParent();
export const isAuthMode = () => !!isAutoClose();

const USED_PARAMS = [TOKEN_KEY, NOGUI_KEY, PARENT_KEY, AUTOCLOSE_KEY];

const clearUsedParams = (params: ParsedQuery) => Object.keys(params)
  .filter(key => !USED_PARAMS.includes(key))
  .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {});

export const clearURL = (params: ParsedQuery) => {
  const clearedURL = qs.stringifyUrl({ url: window.location.pathname, query: clearUsedParams(params) });
  window.history.replaceState(null, document.title, clearedURL);
};

export const isUsedParams = (params: ParsedQuery) => Object.keys(params).some(key => USED_PARAMS.includes(key));
