import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import { makeStyles, InputAdornment, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { IDefaultFilter, Row } from '../types';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  clearIcon: {
    cursor: 'pointer',
    width: 20,
    height: 20,
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.disabled': {
      display: 'none',
    },
  },
}));

const DefaultFilter = <T extends Row>({
  column: { filterValue, setFilter },
  onClear,
}: IDefaultFilter<T>) => {
  const classes = useStyles();
  const inputRef = useRef<HTMLHeadingElement>();

  useEffect(() => {
    if (inputRef) {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current?.focus();
    }
  }, []);

  return (
    <TextField
      className={classes.root}
      inputRef={inputRef}
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ClearIcon
              className={cn(classes.clearIcon, { disabled: !filterValue })}
              onClick={() => {
                setFilter(undefined);
                if (onClear) onClear();
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default DefaultFilter;
