import React from 'react';
import qs from 'query-string';
import ls from 'src/services/localStorage';
import { TOKEN_KEY, NOGUI_KEY, PARENT_KEY, REDIRECT_KEY, AUTOCLOSE_KEY } from '../constants';
import * as utils from './utils';

export * from './utils';

const { isUsedParams, clearURL } = utils;
// @ts-ignore
function withGate(C) {
  return () => {
    const { referrer, location: { search } } = document;
    const params = qs.parse(search);
    if (isUsedParams(params)) {
      ls.setJSON(NOGUI_KEY, params[NOGUI_KEY] === 'true');
      ls.setJSON(PARENT_KEY, params[PARENT_KEY] === 'true');
      ls.setJSON(AUTOCLOSE_KEY, params[AUTOCLOSE_KEY] === 'true');
      ls.set(TOKEN_KEY, params[TOKEN_KEY] as string);
      ls.set(REDIRECT_KEY, referrer);

      clearURL(params);
    }

    return <C />;
  };
}
export default withGate;
