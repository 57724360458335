import React, { memo, useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  FirstPage,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'src/App';
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table';

const useStyles = makeStyles((theme: ITheme) => {
  const { typography: { caption, fontWeightRegular, fontWeightBold } } = theme;
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      fontSize: caption.fontSize,
    },
    action: {
      ...caption,
      fontWeight: fontWeightRegular,
      '&.active': {
        fontWeight: fontWeightBold,
        pointerEvents: 'none',
      },
      minWidth: 40,
    },
    input: {
      width: 40,
    },
    splitter: {
      margin: `0 ${theme.spacing(0.5)}px`,
    },
  };
});

type IActions = Pick<UsePaginationInstanceProps<{}>,
  'pageCount' | 'canPreviousPage' | 'canNextPage' | 'previousPage' | 'nextPage' | 'gotoPage'> & Pick<UsePaginationState<{}>, 'pageIndex'>

const Actions = ({
  pageCount,
  pageIndex,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  gotoPage,
}: IActions) => {
  const { root, action, input, splitter } = useStyles();
  const [currentValue, setCurrentValue] = useState<string>((pageIndex + 1).toString());

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: strValue } = event.target;
    if (!strValue) {
      setCurrentValue('');
      return;
    }
    if (strValue && !/^\d+$/.test(strValue)) return;
    const value = parseInt(strValue, 10);
    if (value < 1 || value > pageCount) {
      setCurrentValue(currentValue);
      return;
    }
    setCurrentValue(value.toString());
  };

  const onBlur = () => {
    if (!currentValue) {
      gotoPage(0);
      return;
    }
    const newPage = parseInt(currentValue, 10) - 1;
    if (newPage !== pageIndex) {
      gotoPage(newPage);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onBlur();
    }
  };

  return (
    <div className={root}>
      <Button
        className={action}
        disabled={!canPreviousPage}
        onClick={() => gotoPage(0)}
        data-seleniumid="pagination-first"
      >
        <FirstPage />
      </Button>
      <Button
        className={action}
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
        data-seleniumid="pagination-previous"
      >
        <KeyboardArrowLeft />
      </Button>
      <input
        type="text"
        value={currentValue}
        onChange={onChange}
        onBlur={onBlur}
        className={input}
        data-seleniumid="pagination-input"
        onKeyDown={onKeyDown}
      />
      <span className={splitter}>/</span>
      <Typography
        variant="body2"
        align="center"
        component="span"
        data-seleniumid="pagination-pagecount"
      >
        {pageCount}
      </Typography>
      <Button
        className={action}
        disabled={!canNextPage}
        onClick={() => nextPage()}
        data-seleniumid="pagination-next"
      >
        <KeyboardArrowRight />
      </Button>
      <Button
        className={action}
        disabled={!canNextPage}
        onClick={() => gotoPage(pageCount - 1)}
        data-seleniumid="pagination-last"
      >
        <LastPage />
      </Button>
    </div>
  );
};

export default memo(Actions);
