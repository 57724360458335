import { SelectFilter, DefaultFilter } from 'src/components/Table/Filters';
import { BypassCell, ErrorMessageCell } from '../components';

export const columns = [
  {
    accessor: 'printVendor',
    Header: 'Print Vendor',
    Filter: DefaultFilter,
  },
  {
    accessor: 'deliveryChannel',
    Header: 'Delivery Channel',
    Filter: DefaultFilter,
  },
  {
    accessor: 'status',
    Header: 'Status',
    Filter: SelectFilter,
    filter: 'equals',
  },
  {
    accessor: 'eventType',
    Header: 'Event Type',
    Filter: SelectFilter,
    filter: 'equals',
  },
  {
    accessor: 'eventName',
    Header: 'Event Name',
    Filter: DefaultFilter,
  },
  {
    accessor: 'eventStartTime',
    Header: 'Start Time',
    Filter: DefaultFilter,
  },
  {
    accessor: 'eventEndTime',
    Header: 'End Time',
    Filter: DefaultFilter,
  },
  {
    accessor: 'bypass',
    Header: 'Bypass',
    disableFilters: true,
    disableSortBy: true,
    Cell: BypassCell,
  },
  {
    accessor: 'errorMessage',
    Header: 'Error Message',
    disableFilters: true,
    disableSortBy: true,
    Cell: ErrorMessageCell,
  },
];
