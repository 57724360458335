import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import createMuiTheme, { ThemeOptions, Theme } from '@material-ui/core/styles/createMuiTheme';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrivateRoute from 'src/router/PrivateRoute';
import ReduxToastr from 'react-redux-toastr';
import Header from 'src/components/layout/Header';
import Footer from 'src/components/layout/Footer';
import { routes } from 'src/router/routes';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'src/redux/store';
import { Palette } from '@material-ui/core/styles/createPalette';
import withAuthProvider, { Auth } from 'src/msal/AuthProvider';
import withGate, { isGUI } from 'src/gate';

interface IPalette extends Palette {
  typical: {
    main: string;
    accent: string;
    white: string;
    black: string;
    fieldset: string;
  },
  black: {
    main: string;
    disabled: string;
    active: string;
  },
}

export interface ITheme extends Theme {
  palette: IPalette;
  [key: string]: any;
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
  [key: string]: any;
  overrides: any;
}

export const theme = createMuiTheme({
  typography: {
    fontWeightMedium: 600,
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
    h5: {
      fontWeight: 700,
    },
  },
  palette: {
    typical: {
      main: '#005176',
      accent: '#135AE1',
      white: '#FFFFFF',
      black: '#000000',
      fieldset: '##BDBDBD',
    },
    primary: {
      main: '#005176',
    },
    secondary: {
      main: '#2B986E',
      light: '#62c99c',
    },
    error: {
      main: '#D42C2C',
    },
    background: {
      default: '#F5F5F5',
    },
    black: {
      main: fade('#000000', 0.65),
      disabled: fade('#000000', 0.35),
      active: fade('#000000', 0.85),
    },
  },
  overrides: {},
} as IThemeOptions) as ITheme;

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette.background.default,
  },
  toastr: {
    '& *': {
      fontFamily: 'Open Sans,Arial,sans-serif',
      color: '#FFFFFF',
      wordBreak: 'break-word',
    },
    '& .rrt-error': {
      '& .rrt-progressbar': {
        filter: 'brightness(85%)',
      },
    },
    '& .rrt-warning': {
      '& .rrt-progressbar': {
        filter: 'brightness(85%)',
      },
    },
    '& .rrt-success': {
      '& .rrt-progressbar': {
        filter: 'brightness(85%)',
      },
    },
  },
});

const App: React.FC<Auth> = (authProps: Auth) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isGUI() && <Header />}
      <main className={classes.main}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" exact />
            <PrivateRoute {...routes.ASSIGNMENTS} {...authProps} />
            <PrivateRoute {...routes.ASSIGNMENTS_STATUS} {...authProps} />
            <PrivateRoute {...routes.DELIVERY_EVENTS} {...authProps} />
            <Route {...routes.NOT_FOUND} />
          </Switch>
        </ConnectedRouter>
      </main>
      {isGUI() && <Footer />}
      <ReduxToastr
        className={classes.toastr}
        timeOut={8000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </ThemeProvider>
  );
};

export default withGate(withAuthProvider(App));
