import React from 'react';

import { ReactComponent as Logo } from 'src/assets/logo.svg';

export default (props: any) => (
  <Logo
    fill="#000"
    width="134"
    height="40"
    {...props}
  />
);
