import React, { useCallback, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TablePagination } from '@material-ui/core';
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table';
import { ITheme } from 'src/App';
import Actions from './Actions';

interface IPagination extends Pick<UsePaginationInstanceProps<{}>,
  'pageCount' | 'canPreviousPage' | 'canNextPage'| 'gotoPage' | 'previousPage' | 'nextPage' | 'setPageSize'>, UsePaginationState<{}> {
  totalAmount: number;
  perPageOptions: number[];
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    border: 'none',
    fontSize: theme.typography.caption.fontSize,
  },
  caption: {
    fontSize: theme.typography.caption.fontSize,
  },
  select: {
    marginTop: theme.spacing(0.5),
  },
}));

const Pagination = ({
  pageSize,
  pageIndex,
  gotoPage,
  pageCount,
  canPreviousPage,
  canNextPage,

  previousPage,
  nextPage,
  setPageSize,

  perPageOptions,
  totalAmount,
}: IPagination) => {
  const classes = useStyles();

  const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    gotoPage(0);
  }, [setPageSize, gotoPage]);

  return (
    <TablePagination
      data-seleniumid="pagination"
      SelectProps={{
        id: 'rows-per-page-selector',
        MenuProps: { MenuListProps: { id: 'rows-per-page-list' } },
      }}
      classes={classes}
      rowsPerPageOptions={perPageOptions}
      count={totalAmount}
      rowsPerPage={pageSize}
      page={pageIndex}
      onChangePage={() => {}}
      onChangeRowsPerPage={onChangeRowsPerPage}
      component="div"
      ActionsComponent={() => (
        <Actions
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          gotoPage={gotoPage}
        />
      )}
    />
  );
};

export default memo(Pagination);
