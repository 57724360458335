import qs from 'query-string';
import { generatePath } from 'react-router';
import _ from 'lodash';
import axios, { CancelTokenSource } from 'axios';
import http from 'src/services/http';

export type ErrorResponse = {
  response: {
    url: string;
    status: number;
    message?: string;
    data?: {
      code: number;
      message: string;
    };
  };
}

interface Request {
  url: string,
  data: object,
  ignoreError: boolean,
  [key: string]: any;
}

interface Params {
  raw: boolean;
  ignoreError: boolean;
  ignoreParams: boolean;
  isList: boolean;
  contentType: string;
  handleHeader: string;
  customPath: boolean;
  method: 'GET' | 'POST' | 'PATCH' | 'HEAD' | 'DELETE';
}

type API = <T>(
  path: string,
  httpParams?: { [paramName: string]: string | number | boolean | undefined },
  params?: Partial<Params>,
) => [Promise<T>, string, any, CancelTokenSource];

export const getQueryParams = (path: string, params?: Record<string, any>) => {
  const queryParams = path.split(':').reduce((acc: Record<string, string>, pathParamName) => {
    if (acc[pathParamName]) return _.omit(acc, pathParamName);
    return acc;
  }, params || {});

  return _.isEmpty(queryParams) ? null : queryParams;
};

const handleError = (error:any) => {
  const { data, message: responseMessage } = error.response;
  let dataMessage;
  if (data) {
    const { message } = data;
    dataMessage = message;
  }

  return dataMessage || responseMessage;
};

const api: API = (
  path,
  httpParams = {},
  params = {},
) => {
  const {
    raw = false,
    method = 'GET',
    ignoreError = false,
    isList = false,
    contentType = '',
    handleHeader = '',
    customPath = false,
    ignoreParams = false,
  } = params;

  const initRequest: Request = {
    method,
    url: customPath ? path : generatePath(path, httpParams),
    data: httpParams,
    ignoreError,
    responseType: contentType.includes('text') ? 'text' : 'json',
  };

  let { url } = initRequest;

  if (raw) {
    initRequest.baseURL = '';
  }

  if (method === 'GET') {
    const queryParams = getQueryParams(path, httpParams);
    if (queryParams) url += `?${qs.stringify(httpParams, { encode: false })}`;

    if (!ignoreParams) {
      initRequest.params = httpParams;
    }
  }

  if (contentType) {
    initRequest.headers = {
      'Content-Type': contentType,
    };
  }

  if (!contentType && method === 'PATCH') {
    initRequest.headers = {
      'Content-Type': 'application/merge-patch+json',
    };
  }

  const { CancelToken } = axios;
  const source = CancelToken.source();
  initRequest.cancelToken = source.token;

  return [
    http
      .request(initRequest)
      .then(res => {
        if (res && res.status) {
          if (res.status >= 200 && res.status <= 299) {
            if (isList) {
              return {
                items: res.data,
                itemsCount: parseInt(res.headers['x-total-count'] || 0, 10),
              };
            }

            if (handleHeader) {
              return res.headers[handleHeader];
            }

            return res.data;
          }
          return Promise.reject(res.data);
        }
        return Promise.reject({ response: { data: { message: 'Empty response' } } });
      })
      .catch(err => {
        const { status } = err.response;
        return Promise.reject({
          response: {
            data: { message: handleError(err) },
            status,
          },
        } || err);
      }),
    `${method}${url}`,
    httpParams?.redirectUrl ?? null,
    source,
  ];
};

export default api;
