import React, { forwardRef, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';


export interface IPaperProps {
  className?: string;
}

export interface IPaperLayout extends IPaperProps{
  children: any;
}

const useStyles = makeStyles(theme => ({
  layout: {
    flex: 1,
    display: 'flex',
    height: '100%',
    maxWidth: theme.breakpoints.values.xl,
    maxHeight: '100%',
    borderTop: `1px solid ${theme.palette.common.white}`,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const PaperLayout = forwardRef(({ className, children }: IPaperLayout, ref) => {
  const classes = useStyles();

  return (
    <Paper ref={ref} className={`${classes.layout} ${className}`} elevation={3}>
      {children}
    </Paper>
  );
});

export default memo(PaperLayout);
