import { SET_NETWORK, REMOVE_NETWORK, RequestInfo } from './types';

export const setRequestInfo = (
  url: string,
  payload?: RequestInfo | string,
) => ({
  type: SET_NETWORK,
  url,
  payload,
} as const);

export const removeRequestInfo = (url: string) => ({
  type: REMOVE_NETWORK,
  url,
} as const);
