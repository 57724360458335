import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { toastr } from 'react-redux-toastr';
import { Box, Button, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import BaseLayout from 'src/components/layout/BaseLayout';
import useRequest from 'src/utils/hooks/useRequest';
import { Modal } from 'src/components/Modal';
import { isIframeMode } from 'src/gate';
import SpinnerOverlay from 'src/components/SpinnerOverlay';
import { getPrinters, loadPrinters } from 'src/redux/reducers/printers';
import { sendToVault, getURLParam } from 'src/utils';
import { REDIRECT_TO_VAULT, LOCK_PAGE, ISBN_KEY } from 'src/constants';
import ISBNs from './components/ISBNs';
import Traditional, { getTraditional } from './components/Traditional';
import POD, { getPODValue } from './components/POD';
import { PodValue, TraditionalValue } from './common/Interfaces';
import {
  ASSIGNMENTS_TITLE,
  SAVE_ASSIGNMENTS_BUTTON,
  SUCCESS_ASSIGN,
  WARNING_MODAL_TITLE,

  ASSIGN_MODAL_TITLE,
  ASSIGN_MODAL_DESCRIPTION,
  UNASSIGN_MODAL_TITLE,
  UNASSIGN_MODAL_DESCRIPTION,
} from './common/constants';
import { getAssignPrintVendorBody, isPrintersSelected } from './common/utils';
import { assign } from './common/ducks';

const useStyles = makeStyles(theme => ({
  button: {
    width: 200,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  warningIcon: {
    width: 40,
    height: 40,
    color: theme.palette.warning.main,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
  },
}));

const modalStyles = makeStyles({
  paper: {
    maxWidth: '50%',
    minWidth: '650px !important',
  },
});

const Assignments = () => {
  const location = useLocation();
  const printers = useSelector(getPrinters);

  const dispatch = useDispatch();
  const { button, warningIcon } = useStyles();
  const modalClasses = modalStyles();
  const [isbns, setISBNs] = useState<string|null>(getURLParam(location.search, ISBN_KEY));
  const [lockUI, setLockUI] = useState<boolean>(!!isbns);
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false);
  const [openWarningModal, setWarningModal] = useState<boolean>(false);
  const [traditional, setTraditional] = useState<TraditionalValue>(getTraditional(printers));
  const [pod, setPOD] = useState<PodValue>(getPODValue(printers));

  const lockPage = (flag: boolean) => {
    if (!isIframeMode()) return;

    sendToVault(LOCK_PAGE, flag ? 'true' : '');
    setLockUI(flag);
  };

  useEffect(() => {
    const isEmpty = !isbns && !isPrintersSelected(traditional, pod);

    if (isEmpty && lockUI) {
      lockPage(false);
    }
    if (!isEmpty && !lockUI) {
      lockPage(true);
    }
  }, [isbns, traditional, pod]);

  useEffect(() => {
    dispatch(loadPrinters());
  }, []);

  useEffect(() => {
    if (printers.length) {
      setTraditional(getTraditional(printers));
      setPOD(getPODValue(printers));
    }
  }, [printers]);


  const onClose = () => {
    sendToVault(REDIRECT_TO_VAULT);
  };

  const onCancel = (isWarning:boolean) => () => {
    if (isWarning) {
      setWarningModal(true);
    }
    else {
      onClose();
    }
  };

  const onSave = () => {
    dispatch(assign(getAssignPrintVendorBody(printers, isbns || '', traditional, pod), () => {
      setOpenSaveModal(false);
      lockPage(false);
      toastr.success('Success', SUCCESS_ASSIGN);
    }));
  };

  const getPrintersRequest = useRequest({ route: { method: 'POST', endpoint: 'printers' } });
  const assignRequestInfo = useRequest({
    route: { method: 'POST', endpoint: 'assignPrintDelivery', params: getAssignPrintVendorBody(printers, isbns || '', traditional, pod) },
  });

  const onISBNsChange = useCallback((value: string) => { setISBNs(value); }, [setISBNs]);
  const onPODChange = useCallback((value: PodValue) => { setPOD(value); }, [setPOD]);
  const onTraditionalChange = useCallback((value: TraditionalValue) => { setTraditional(value); }, [setTraditional]);

  const printerSelected = isPrintersSelected(traditional, pod);
  const isSaveDisable = !isbns;
  const isWarning = !!(isbns || printerSelected);

  return (
    <BaseLayout>
      <SpinnerOverlay showOverlay={getPrintersRequest?.status === 'fetching'}>
        <Box display="flex" flexDirection="column" padding="24px 15%" width="100%">
          <Box display="flex" justifyContent="space-around">
            <ISBNs
              value={isbns || ''}
              onChange={onISBNsChange}
            />
            <Box display="flex" flexDirection="column" marginLeft={3}>
              <Typography variant="h6">
                {ASSIGNMENTS_TITLE}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Traditional
                  printers={printers}
                  value={traditional}
                  onChange={onTraditionalChange}
                />
                <POD
                  printers={printers}
                  value={pod}
                  onChange={onPODChange}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              className={button}
              disableElevation
              color="primary"
              variant="contained"
              data-seleniumid="save-button"
              onClick={() => setOpenSaveModal(true)}
              disabled={isSaveDisable}
            >
              {SAVE_ASSIGNMENTS_BUTTON}
            </Button>
            {
              (isIframeMode()) && (
                <Button
                  className={button}
                  disableElevation
                  color="primary"
                  variant="outlined"
                  data-seleniumid="cancel-button"
                  onClick={onCancel(isWarning)}
                >
                  Cancel
                </Button>
              )
            }
          </Box>
        </Box>
      </SpinnerOverlay>
      <Modal
        open={openSaveModal}
        onCloseEffect={() => setOpenSaveModal(false)}
        data-seleniumid="confirmation-modal"
        classes={modalClasses}
        title={printerSelected ? ASSIGN_MODAL_TITLE : UNASSIGN_MODAL_TITLE}
        isLoading={assignRequestInfo?.status === 'fetching'}
        actions={[{
          id: 'assign-confirm',
          title: 'Confirm',
          type: 'primary',
          onClick: onSave,
        }]}
        disableBackdropClick={false}
      >
        <Box height={200} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h6" display="inline" align="center" data-seleniumid="confirmation-modal-description">
            {printerSelected ? ASSIGN_MODAL_DESCRIPTION : UNASSIGN_MODAL_DESCRIPTION}
          </Typography>
        </Box>
      </Modal>
      <Modal
        open={openWarningModal}
        onCloseEffect={() => setWarningModal(false)}
        data-seleniumid="warning-modal"
        classes={modalClasses}
        actions={[{
          id: 'warning-confirm',
          title: 'OK',
          type: 'primary',
          onClick: onClose,
        }]}
        disableBackdropClick={false}
      >
        <Box height={200} display="flex" alignItems="center">
          <Box display="flex" paddingRight={2}>
            <WarningIcon className={warningIcon} />
            <Typography variant="h6" display="inline" align="center" data-seleniumid="warning-modal-description">
              {WARNING_MODAL_TITLE}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </BaseLayout>
  );
};

export default Assignments;
