import React from 'react';
import { makeStyles, MenuItem, Box, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import cn from 'classnames';
import { IDefaultFilter, Row } from '../types';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  clearIcon: {
    cursor: 'pointer',
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.disabled': {
      display: 'none',
    },
  },
}));

const DEFAULT_SELECT = 'All';

const SelectFilter = <T extends Row>({
  column: { filterValue, setFilter, preFilteredRows, id },
}: IDefaultFilter<T>) => {
  const classes = useStyles();
  const options = React.useMemo(() => {
    const options = new Set();
    // @ts-ignore
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    // @ts-ignore
    return [...options.values()];
  }, [id, preFilteredRows]);

  return (
    <Box display="flex" alignItems="center">
      <TextField
        className={classes.root}
        select
        value={filterValue || DEFAULT_SELECT}
        onChange={(e) => {
          setFilter((!e.target.value || e.target.value === DEFAULT_SELECT) ? undefined : e.target.value);
        }}
        SelectProps={{
          MenuProps: { disablePortal: true },
        }}
      >
        {[DEFAULT_SELECT, ...options].map((option, i) => (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {filterValue && (
        <ClearIcon
          className={cn(classes.clearIcon, { disabled: !filterValue })}
          onClick={() => setFilter(undefined)}
        />
      )}
    </Box>
  );
};

export default SelectFilter;
