const ls = window.localStorage;

const get = (key: string) : string | null => {
  try {
    return ls.getItem(key);
  }
  catch (e) {
    return null;
  }
};

const set = (key: string, value: string) => {
  try {
    return ls.setItem(key, value);
  }
  catch (e) {
    return null;
  }
};

const setJSON = (key: string, obj: any) => {
  try {
    return set(key, JSON.stringify(obj));
  }
  catch (e) {
    // eslint-disable-next-line
    console.log('setJSON', e);
  }
  return null;
};

const getJSON = (key: string) => {
  const data = get(key);
  if (!data) return null;
  try {
    return JSON.parse(data);
  }
  catch (e) {
    // eslint-disable-next-line
    console.log('getJSON', e);
  }
  return null;
};

const remove = (key: string) => {
  try {
    return ls.removeItem(key);
  }
  catch (e) {
    return false;
  }
};

const clear = () => {
  try {
    return ls.clear();
  }
  catch (e) {
    return false;
  }
};

export default {
  get,
  set,
  setJSON,
  getJSON,
  remove,
  clear,
};
