import React from 'react';
import { Tooltip as MuiTooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ITheme } from 'src/App';

const useClasses = makeStyles((theme: ITheme) => ({
  tooltip: {
    backgroundColor: theme.palette.typical.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: '1rem',
  },
}));

const Tooltip = (props: TooltipProps) => {
  const classes = useClasses();
  return <MuiTooltip classes={classes} {...props} />;
};

export default Tooltip;
