import _ from 'lodash';
import { Printers } from 'src/redux/reducers/printers';
import { PodValue, TraditionalValue } from './Interfaces';

const parseISBNs = (isbns: string) => _.compact(isbns.trim().split(/[\s+,.:;]/g));

export const getAssignPrintVendorBody = (printers: Printers, isbns: string, traditionals:TraditionalValue, pods:PodValue) => {
  const traditional = Object.entries(traditionals)
    .filter(([_, flag]) => flag)
    .map(([id]) => _.find(printers, { id }) || null);

  const pod = _.values(_.mapValues(pods, id => _.find(printers, { id }) || null));

  return {
    isbns: parseISBNs(isbns),
    printers: _.compact([
      ...traditional,
      ...pod,
    ]),
  };
};

export const isPrintersSelected = (traditionals:TraditionalValue, pods:PodValue) =>
  _.compact([...Object.values(traditionals), ...Object.values(pods)]).length;
