import { assignPrintDelivery } from 'src/redux/api';
import { FAILED_ACTION_MESSAGE } from 'src/constants';
import { toastrErrorWithoutAuth } from 'src/utils';
import { ErrorResponse } from 'src/services/api';

export const assign = (body: any, success?: Function) => ({
  type: 'ASSIGN_PRINT_VENDOR',
  asyncCall: () => assignPrintDelivery(body),
  transformResult: (res: any) => {
    if (success) success();
    return res;
  },
  transformError: (e: ErrorResponse) => {
    toastrErrorWithoutAuth(e, FAILED_ACTION_MESSAGE('update assignments'));
    return e;
  },
});
