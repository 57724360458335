import React from 'react';
import cn from 'classnames';
import Table from 'src/components/Table';
import { makeStyles, Typography } from '@material-ui/core';
import { Section, columns } from './utils';

const useStyles = makeStyles((theme) => ({
  text: {
    paddingLeft: theme.spacing(0.5),
  },
  title: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ErrorSection = ({ title, subTitle, data }:Section) => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="subtitle1" className={cn(classes.title, classes.text)}>
        {title}
      </Typography>
      <Typography variant="subtitle1" className={classes.text}>
        {subTitle}
      </Typography>
      <Table
        seleniumId="errors"
        data={data}
        columns={columns}
        ignoreFocus
      />
    </div>
  );
};

export default ErrorSection;
