import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgLogo from 'src/components/SvgLogo';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    borderBottom: `5px solid ${theme.palette.secondary.main}`,
  },
}));

const Header: React.FC = () => {
  const { root } = useStyles();
  return (
    <header className={root}>
      <SvgLogo />
    </header>
  );
};

export default Header;
