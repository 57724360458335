/* eslint-disable no-console */
import { Row } from 'src/components/Table/types';
import { v4 } from 'uuid';

const SUBTITLE = 'Failure Reason(s) :';
const ISBN = 'ISBN';

export const columns = [
  {
    accessor: 'text',
    Header: 'Text',
    width: 62,
  },
  {
    accessor: 'fileName',
    Header: 'File Name',
    width: 30,
  },
  {
    accessor: 'status',
    Header: 'status',
    width: 8,
  },
];

const emptyItem: Item = {
  id: v4(),
  text: '',
  fileName: '',
  status: '',
};

export interface Item extends Row {
  text:string;
  fileName:string;
  status:string;
}

export interface Section {
  title:string;
  subTitle:string;
  data: Item[];
}

const getEmptySection = (value:string, defaultIsbn:string): Section => ({
  title: `${ISBN} ${defaultIsbn}`,
  subTitle: SUBTITLE,
  data: [{
    ...emptyItem,
    text: value,
  }],
});

const getSection = (type:string, isbn:string, items: Item[]) => ({
  title: `${type} ${isbn}`,
  subTitle: SUBTITLE,
  data: items,
});

const parseTableRows = (html:string) => {
  const columnsKeys = columns.map(el => el.accessor);
  return Array.from(
    new DOMParser().parseFromString(`<table>${html}</table>`, 'text/html').querySelectorAll('tr'),
    // @ts-ignore
    row => [...row.cells].reduce((acc, cell, i) => ({ ...acc, [columnsKeys[i]]: cell.textContent }), emptyItem));
};

const cutRequiredItems = (items:Item[], files:string[]): Item[] => {
  const sectionItems:Item[] = [];
  items.forEach((item, i, list) => {
    if (files.includes(item.fileName)) {
      sectionItems.push(...list.splice(i, 1));
    }
  });
  return sectionItems;
};

const parseRequireSections = (stringDescription:string, defaultIsbn:string, defaultItems: Item[]): Section[] => {
  const items = [...defaultItems];
  const groups = stringDescription.split(';').map(sectionDescription => {
    const [fullType, ...files] = sectionDescription.split(',');
    const [type, isbn] = fullType.split(' ');
    return { type, isbn, files };
  });

  if (!groups.length) return [getSection(ISBN, defaultIsbn, items)];
  if (groups.length === 1) return [getSection(groups[0].type, groups[0].isbn, items)];

  const sections: Section[] = [];
  groups.forEach(({ type, isbn, files }) => {
    sections.push(getSection(type, isbn, cutRequiredItems(items, files)));
  });

  // if rows don't contain any require types
  if (defaultItems.length === items.length) return [getSection(ISBN, defaultIsbn, items)];

  // add other items to last type
  const lastSection = sections.pop();
  // @ts-ignore
  sections.push({
    ...lastSection,
    data: [
      ...(lastSection?.data || []),
      ...items,
    ],
  });
  return sections;
};

export const getSections = (value:string, defaultIsbn:string | null): Section[] => {
  if (!value || !defaultIsbn) return [];

  try {
    if (!/<tr>/.test(value)) return [getEmptySection(value, defaultIsbn)];
    const items = parseTableRows(value.replace(/null/g, ''));
    try {
      const requireSectionsMatch = value.match(/^<!--(((?!-->).)*)-->/) || [];
      if (requireSectionsMatch?.length > 1) {
        return parseRequireSections(requireSectionsMatch[1], defaultIsbn, [...items]);
      }
    }
    catch (e) {
      console.error(`${defaultIsbn} / ErrorMessages parse error: `, e);
    }
    return [getSection(ISBN, defaultIsbn, items)];
  }
  catch (e) {
    console.error('ParseError: ', e);
    return [];
  }
};
