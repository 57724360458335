import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from 'src/assets/logo.png';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: '#CCCCCC',
    height: 50,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    maxWidth: theme.breakpoints.values.xl,
    fontSize: '11px',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[700],
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        "Copyright © 2000-{currentYear} by John Wiley & Sons, Inc., or related companies. All rights reserved."
      </div>
      <img src={logo} alt="" />
    </footer>
  );
};

export default Footer;
