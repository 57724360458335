import React, { useMemo, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useLocation } from 'react-router';
import { ISBN_KEY } from 'src/constants';
import { getURLParam } from 'src/utils';
import { Modal } from 'src/components/Modal';
import { getSections } from './utils';
import ErrorSection from './Section';

const useStyles = makeStyles((theme) => ({
  link: {
    cursor: 'pointer',
  },
  content: {
    margin: theme.spacing(2),
    '& > div:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
}));

const ErrorMessageCell = (props: any) => {
  const classes = useStyles();
  const location = useLocation();
  const isbn = getURLParam(location.search, ISBN_KEY);

  const [open, setOpen] = useState<boolean>(false);
  const { value } = props;

  const sectionsInfo = useMemo(() => getSections(value, isbn), [value, isbn]);
  if (!value || !isbn) return null;

  return (
    <>
      <Typography
        variant="caption"
        color="primary"
        className={classes.link}
        onClick={() => setOpen(true)}
      >
        MESSAGE
      </Typography>
      <Modal
        open={open}
        onCloseEffect={() => setOpen(false)}
        data-seleniumid="error-message-modal"
        title="Messages"
        actions={[{
          id: 'ok-button',
          title: 'OK',
          type: 'primary',
          onClick: () => setOpen(false),
        }]}
        disableBackdropClick={false}
        showCancel={false}
      >
        <div className={classes.content}>
          {sectionsInfo.map(section => (
            <ErrorSection {...section} key={section.title} />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ErrorMessageCell;
