/* eslint-disable no-console */
import React, { Component } from 'react';
import axiosInstance from 'src/services/http';
import { UNAUTHORIZED, FORBIDDEN } from 'src/constants/httpCode';
import { sendToVault } from 'src/utils';
import { REDIRECT_TO_VAULT_FOR_AUTH, TOKEN_HEADER_KEY } from 'src/constants';
import {
  msalApp,
  getMSALToken,
  useMSAL,
  // isMSALPriority,
} from './utils';
import {
  isSPAMode,
  isIframeMode,
  isAuthMode,

  // getParentToken,
  clearParentToken,
  clearAutoClose,
} from '../gate';

export interface Auth {
  isAuthenticated?: boolean;
}

interface State {
  error: string | null;
  isAuthenticated: boolean;
}

export default (C: React.ComponentType<Auth>) =>
  class AuthProvider extends Component<any, State> {
    constructor(props: any) {
      super(props);

      this.state = {
        isAuthenticated: isAuthMode() ? false : isIframeMode(),
        error: null,
      };

      // @ts-ignore
      this.interceptorRequest = axiosInstance.interceptors.request.use(async ({ headers, ...rest }) => ({
        ...rest,
        headers: {
          ...headers,
          [TOKEN_HEADER_KEY]: await this.getRelevantToken(),
        },
      }), error => Promise.reject(error));

      // @ts-ignore
      this.interceptorResponse = axiosInstance.interceptors.response.use(null,
        async (error: any) => {
          if (error && error.response) {
            const { status } = error.response;
            if ([UNAUTHORIZED, FORBIDDEN].includes(status)) {
              this.handleUnauthorized();
            }
          }

          return Promise.reject(error);
        });
    }

    async componentDidMount() {
      if (isIframeMode()) return;

      msalApp.handleRedirectCallback(error => {
        if (error) {
          const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.';
          this.setState({
            error: errorMessage,
          });
        }
      });

      const userAccount = msalApp.getAccount();
      if (!userAccount) {
        msalApp.loginRedirect({});
      }
      else if (isAuthMode()) {
        clearAutoClose();
        useMSAL();
        window.close();
      }
      else {
        this.setState({
          isAuthenticated: true,
        });
      }
    }

    componentWillUnmount() {
      // @ts-ignore
      axiosInstance.interceptors.request.eject(this.interceptorRequest);
      // @ts-ignore
      axiosInstance.interceptors.response.eject(this.interceptorResponse);
    }

    getRelevantToken() {
      // if (isSPAMode()) {
      //   return getMSALToken();
      // }
      //
      // if (isIframeMode()) {
      //   return isMSALPriority() ? getMSALToken() : getParentToken();
      // }
      return getMSALToken();
    }

    async handleUnauthorized() {
      if (isSPAMode()) {
        msalApp.loginRedirect({});
      }

      if (isIframeMode()) {
        clearParentToken();
        this.createSPAAuthPopup();
      }
    }

    createSPAAuthPopup = () => {
      sendToVault(REDIRECT_TO_VAULT_FOR_AUTH);
    }

    render() {
      const { isAuthenticated } = this.state;

      return (
        <C
          isAuthenticated={isAuthenticated}
        />
      );
    }
  };
