/* eslint-disable react/jsx-key */
import React, { PropsWithChildren, ReactElement, useEffect, useRef } from 'react';
import { useTable, useSortBy, usePagination, useFilters, HeaderGroup, ColumnInstance, Row, Cell } from 'react-table';
import { TableBody, TableCell, TableHead, TableRow, Table as MaUTable, fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Pagination from './Pagination';
import HeaderCell from './HeaderCell';
import { ITable, Row as IRow } from './types';

export const DEFAULT_HEADER_HEIGHT = 42;
export const DEFAULT_CELL_HEIGHT = 38;
const DEFAULT_PER_PAGE_OPTIONS = [10, 25, 50];

const NO_RESULTS_MESSAGE = 'No results found';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    overflowY: 'auto',
  },
  contentWrap: {
    width: '100%',
  },
  loaderWrap: {
    backgroundColor: fade(theme.palette.background.paper, 0.7),
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&div': {
      backgroundColor: '#ff0',
    },
  },
  error: {
    width: '100%',
    margin: '2rem auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const useTableStyles = makeStyles(theme => ({
  table: {
    tableLayout: 'fixed',
  },
  tbody: {},
  headerRow: {
    backgroundColor: theme.palette.grey[50],
  },
  headerCell: {
    padding: theme.spacing(1, 1),
    height: DEFAULT_HEADER_HEIGHT,
    ...theme.typography.subtitle2,
  },
  row: {
    '&:last-child': {
      borderBottom: 0,
    },
  },
  rowCell: {
    wordWrap: 'break-word',
    padding: theme.spacing(1, 1),
    height: DEFAULT_CELL_HEIGHT,
    ...theme.typography.caption,
  },
}));

export function Table<T extends IRow>(props: PropsWithChildren<ITable<T>>): ReactElement {
  const {
    columns,
    data,
    seleniumId,
    classes: classesOverride,
    isLoading = false,
    showPagination = false,
    rowsPerPageOptions = DEFAULT_PER_PAGE_OPTIONS,
    filterable = false,
    sortable = false,
    ignoreFocus = false,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    state: { pageIndex, pageSize },
    gotoPage,
    pageCount,
    canPreviousPage,
    canNextPage,

    previousPage,
    nextPage,
    setPageSize,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: rowsPerPageOptions[0] },
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const classes = useStyles();
  const tableRef = useRef<HTMLDivElement>(null);
  const tableClasses = useTableStyles({ classes: classesOverride });
  const items = showPagination ? page : rows;
  const error = !isLoading && !items.length ? NO_RESULTS_MESSAGE : '';

  useEffect(() => {
    if (!ignoreFocus && tableRef?.current?.scrollIntoView) {
      tableRef.current.scrollIntoView();
    }
  }, [pageIndex, pageSize]);

  return (
    <div data-seleniumid={`${seleniumId || 'component'}-table`} className={classes.root}>
      <div className={classes.contentWrap} ref={tableRef}>
        <MaUTable
          {...getTableProps()}
          className={tableClasses.table}
        >
          <TableHead>
            {headerGroups.map((headerGroup: HeaderGroup<T>) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} className={tableClasses.headerRow}>
                {headerGroup.headers.map((column: ColumnInstance<T>) => (
                  <TableCell
                    {...column.getHeaderProps(sortable ? column.getSortByToggleProps({ title: undefined }) : [])}
                    // @ts-ignore
                    width={`${column.width}%`}
                    className={tableClasses.headerCell}
                  >
                    <HeaderCell
                      column={column}
                      filterable={filterable}
                      sortable={sortable}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            {...getTableBodyProps()}
            className={tableClasses.tbody}
          >
            {items.map((row: Row<T>) => {
              prepareRow(row);
              const { original } = row;
              const rowId = original.id;
              return (
                <TableRow
                  {...row.getRowProps()}
                  className={tableClasses.row}
                  data-seleniumid={`${rowId}-row`}
                >
                  {row.cells.map((cell: Cell<T>) => (
                    <TableCell
                      {...cell.getCellProps()}
                      className={tableClasses.rowCell}
                      data-seleniumid={`${rowId}-${cell?.column?.id}-cell`}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
        {error && <div className={classes.error}><Typography variant="h6" data-seleniumid="table-error">{error}</Typography></div>}
        {(!error && showPagination) && (
          <Pagination
            perPageOptions={rowsPerPageOptions}
            totalAmount={rows.length}
            pageIndex={pageIndex}
            pageSize={pageSize}
            gotoPage={gotoPage}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            setPageSize={setPageSize}
          />
        )}
      </div>
      {isLoading && <div className={classes.loaderWrap}><CircularProgress /></div>}
    </div>
  );
}

export default Table;
