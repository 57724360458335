import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getURLParam } from 'src/utils';
import AssignmentsStatus from 'src/pages/AssignmentsStatus';
import { ISBN_KEY } from 'src/constants';
import useRequest from 'src/utils/hooks/useRequest';
import { loadDeliveryEntities, getDeliveryEvents } from 'src/redux/reducers/deliveryEvents';
import Table from 'src/components/Table';
import { columns } from './common/tablesConfiguration';

const ROWS_OPTIONS = [5, 10, 20, 50, 100];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    alignItems: 'center',
  },
}));

const DeliveryEvents = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const isbn = getURLParam(location.search, ISBN_KEY);
  const events = useSelector(getDeliveryEvents);

  useEffect(() => {
    if (isbn) {
      dispatch(loadDeliveryEntities(isbn));
    }
  }, []);

  const getEventsRequest = useRequest({ route: { endpoint: 'deliveryEvents', params: { isbn }, paramType: 'urlGetParams' } });
  const bypassRequest = useRequest({ route: { endpoint: 'bypassEvent', method: 'POST' } });
  const isLoadEvents = !getEventsRequest || getEventsRequest?.status === 'fetching';
  const isLoadBypass = bypassRequest?.status === 'fetching';
  const isLoading = !!isbn && (isLoadEvents || isLoadBypass);
  const columnsConfig = useMemo(() => columns, []);
  const data = useMemo(() => events, [events]);

  return (
    <div className={classes.root}>
      <AssignmentsStatus />
      <Box marginTop={4}>
        <Table
          isLoading={isLoading}
          seleniumId="delivery-events"
          data={data}
          columns={columnsConfig}
          rowsPerPageOptions={ROWS_OPTIONS}
          showPagination
          filterable
          sortable
        />
      </Box>
    </div>
  );
};

export default DeliveryEvents;
