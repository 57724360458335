import { RootState } from 'src/redux/store';
import { InferActionTypes } from 'src/redux/utils';
import * as actions from './actions';
import { LOAD_PRINTERS_ASSIGNMENTS_SUCCESS, PrintersAssignment } from './types';

export * from './actions';
export * from './types';

export const initialState: PrintersAssignment = [];

export type ActionTypes = InferActionTypes<typeof actions>;

export default (
  state = initialState,
  action: ActionTypes,
): typeof initialState => {
  switch (action.type) {
    case LOAD_PRINTERS_ASSIGNMENTS_SUCCESS:
      return action.payload;
    default: return state;
  }
};

export const getPrinters = (state: RootState) => state.printersAssignments;
