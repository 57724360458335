/* eslint-disable import/no-unresolved */
import { FAILED_ACTION_MESSAGE } from 'src/constants';
import { v4 } from 'uuid';
import { ErrorResponse } from 'src/services/api';
import { toastrErrorWithoutAuth } from 'src/utils';
import { getDeliveryEvents, bypassEvent } from 'src/redux/api';

import {
  LOAD_DELIVERY_EVENTS,
  LOAD_DELIVERY_EVENTS_SUCCESS,
  BYPASS_EVENT,
  BYPASS_EVENT_SUCCESS,
  DeliveryEvent,
  DeliveryEventResponse,
} from './types';

export const loadDeliveryEntities = (isbn: string) => ({
  type: LOAD_DELIVERY_EVENTS,
  transformError: (e: ErrorResponse) => {
    toastrErrorWithoutAuth(e, FAILED_ACTION_MESSAGE('load delivery events'));
    return e;
  },
  transformResult: (res: DeliveryEventResponse[]) => {
    const data = res?.length ? res : [];
    return data.map(el => ({
      ...el,
      id: el?.id || v4(),
    }));
  },
  asyncCall: () => getDeliveryEvents(isbn),
} as const);

export const byPassEvent = (entity: DeliveryEvent) => ({
  type: BYPASS_EVENT,
  transformError: (e: ErrorResponse) => {
    toastrErrorWithoutAuth(e, FAILED_ACTION_MESSAGE('bypass event'));
    return e;
  },
  transformResult: () => entity,
  asyncCall: () => bypassEvent(entity),
} as const);

export const setEvents = (events: DeliveryEvent[]) => ({
  type: LOAD_DELIVERY_EVENTS_SUCCESS,
  payload: events,
} as const);

export const updateEvent = (event: DeliveryEvent) => ({
  type: BYPASS_EVENT_SUCCESS,
  payload: event,
} as const);
