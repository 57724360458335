import React, { memo, InputHTMLAttributes } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, Radio, RadioGroup, FormControlLabel, Typography } from '@material-ui/core';
import { Printers } from 'src/redux/reducers/printers';
import { PodValue } from '../common/Interfaces';
import { POD_TYPE, POD_TITLE } from '../common/constants';

interface IPOD {
  printers: Printers
  value:PodValue,
  onChange:(value:PodValue) => void;
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  radioGroup: {
    marginLeft: theme.spacing(3),
  },
}));

const useRadioActive = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[400],
  },
}));

export const getPODValue = (printers: Printers): PodValue => {
  const groupedPOD = _.groupBy(printers.filter(printer => printer.printerTypeName === POD_TYPE), 'printerName');
  return _.mapValues(groupedPOD, () => null);
};

const POD = ({ printers, value, onChange }: IPOD) => {
  const { radioGroup, title } = useStyles();
  const radioActive  = useRadioActive();

  const onSectionSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    if (!checked) {
      onChange({ ...value, [name]: null });
    }
    else {
      const printer = _.find(printers, { printerName: name });
      onChange({ ...value, [name]: _.get(printer, 'id', null) });
    }
  };

  const onVariantSelect = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, [type]: event.target.value });
  };

  return (
    <Box display="flex" flexDirection="column" marginTop={2} width={400}>
      <Typography variant="body1" gutterBottom className={title}>
        {POD_TITLE}
      </Typography>
      {Object.entries(value).map(([type, selectedId]) => (
        <div key={type}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={!!selectedId}
                name={type}
                onChange={onSectionSelect}
                color="primary"
                inputProps={{ 'data-seleniumid': `${type.toLowerCase()}-checkbox` } as InputHTMLAttributes<HTMLInputElement>}
              />
            )}
            label={type}
          />
          <RadioGroup
            className={radioGroup}
            aria-label={type}
            name={type}
            value={value[type]}
            onChange={onVariantSelect(type)}
          >
            {_.filter(printers, { printerName: type }).map(({ id, podType, channelName }) => {
              const radioClasses = value[type] === null ? radioActive : {};
              return (
                <FormControlLabel
                  key={id}
                  value={id}
                  classes={radioClasses}
                  control={(
                    <Radio
                      color="primary"
                      classes={radioClasses}
                      inputProps={{
                        'data-seleniumid': `${type.toLowerCase()}-${podType.toLowerCase()}-radio` } as InputHTMLAttributes<HTMLInputElement>
                      }
                    />
                  )}
                  label={channelName}
                  data-seleniumid={`${type.toLowerCase()}-${podType.toLowerCase()}`}
                />
              );
            })
            }
          </RadioGroup>
        </div>
      ))}
    </Box>
  );
};

export default memo(POD);
