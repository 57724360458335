import { toastr } from 'react-redux-toastr';
import qs from 'query-string';

import { ErrorResponse } from 'src/services/api';
import { getRedirectURL } from 'src/gate';
import { UNAUTHORIZED, FORBIDDEN } from 'src/constants/httpCode';
import { PROD_HOSTNAME } from 'src/constants';

export const isProd = () => window.location.hostname?.includes(PROD_HOSTNAME);

export const sendToVault = (func: string, message?: string) => {
  const url = getRedirectURL();
  window.parent.postMessage({
    func,
    message,
  }, url ? new URL(url).origin : '*');
};

export const toastrErrorWithoutAuth = (error: ErrorResponse, message: string) => {
  const { status } = error.response;
  if (![UNAUTHORIZED, FORBIDDEN].includes(status)) {
    const msg = error?.response?.data?.message || message;
    toastr.error('Failed', msg);
    // toastr.error('Failed', isProd() ? message : msg);
  }
};

export const getURLParam = (search: string, key: string) => {
  const isbn: string = qs.parse(search)?.[key] as string;
  return isbn || null;
};
