import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, generatePath, match as Match } from 'react-router';
import qs from 'query-string';

import endpoints from 'src/redux/api/endpoints';
import { removeRequestInfo } from 'src/redux/reducers/network';
import { getRequestInfo } from 'src/redux/reducers/network/selectors';

type ParamType = 'urlParams' | 'urlGetParams';
interface Params {
  route: Partial<{
    method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
    endpoint: keyof typeof endpoints;
    params: Match['params'];
    paramType?: ParamType;
  }>;
  remove: boolean;
  showError: boolean;
}

const getURL = (path: string, params:any, paramType: ParamType) => {
  if (paramType === 'urlGetParams') return `${path}?${qs.stringify(params)}`;
  return generatePath(path, params);
};

const useRequest = (hookParams?: Partial<Params>) => {
  const dispatch = useDispatch();

  const {
    route: {
      method = 'GET',
      endpoint = null,
      params: routeParams = undefined,
      paramType = 'urlParams',
    } = {},
    remove = false,
    showError = false,
  } = hookParams || {};
  const routeMatch = useRouteMatch<{ path: string }>();

  const path = endpoint
    ? endpoints[endpoint]
    : routeMatch.path;
  const params = routeParams || routeMatch.params;
  const url = `${method}${getURL(path, params, paramType)}`;
  const requestInfo = useSelector(getRequestInfo(url));

  useEffect(() => {
    if (showError && requestInfo?.status === 'failure') {
      // TODO handle displaying error
    }
  }, [requestInfo?.status]);

  useEffect(() => () => {
    if (remove) dispatch(removeRequestInfo(url));
  }, []);

  return requestInfo;
};

export default useRequest;
