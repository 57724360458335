import apiCall from 'src/services/api';
import { Printers } from 'src/redux/reducers/printers/types';
import { PrintersAssignmentResponse } from 'src/redux/reducers/printersAssignments/types';
import { DeliveryEventResponse } from 'src/redux/reducers/deliveryEvents/types';
import endpoints from './endpoints';

export const getPrintersAssignments = (isbn: string) =>
  apiCall<PrintersAssignmentResponse>(endpoints.printerAssignments, { isbn });
export const getPrinters = () => apiCall<Printers>(endpoints.printers, {}, { method: 'POST' });
export const assignPrintDelivery = (params: any) =>
  apiCall<any>(endpoints.assignPrintDelivery, params, { method: 'POST' });

export const getDeliveryEvents = (isbn: string) => apiCall<DeliveryEventResponse[]>(endpoints.deliveryEvents, { isbn });
export const bypassEvent = (entity: any) => apiCall<boolean>(endpoints.bypassEvent, entity, { method: 'POST' });
