import React, { FC } from 'react';
import cn from 'classnames';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { IShowOverlay, IOverlayClasses } from './interfaces';

const useStyles = makeStyles({
  wrap: {
    width: '100%',
    flex: 1,
    position: 'relative',
    display: 'flex',
  },
  loader: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    position: 'absolute',
  },
  content: {
    width: '100%',
    flex: 1,
    display: 'flex',
    '&.loading': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
});

const SpinnerOverlay: FC<IShowOverlay> = ({ showOverlay, children }) => {
  const classes: IOverlayClasses = useStyles();

  return (
    <div className={classes.wrap}>
      {showOverlay && (
        <div className={cn({ [classes.loader]: showOverlay })}>
          <CircularProgress />
        </div>
      ) }
      <div className={cn(classes.content, { loading: showOverlay })}>
        {children}
      </div>
    </div>
  );
};

export default SpinnerOverlay;
