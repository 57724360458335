export const ASSIGNMENTS_TITLE = 'Print Vendor Assignments for Automatic delivery';
export const SAVE_ASSIGNMENTS_BUTTON = 'Save assignments';

export const TRADITIONAL_TYPE = 'TRADITIONAL';
export const TRADITIONAL_TITLE = 'Traditional';
export const TRADITIONAL_SELECT_BUTTON = 'Select all';

export const POD_TYPE = 'POD';
export const POD_TITLE = 'POD';

export const ASSIGN_MODAL_TITLE = 'Confirm Assignments for Automatic Deliveries';
export const ASSIGN_MODAL_DESCRIPTION = 'You are about to update assignments of ISBN(s) to selected Printer(s). Previous assignments will be overwritten.';
export const UNASSIGN_MODAL_TITLE = 'Confirm Un-Assignments for Automatic Deliveries';
export const UNASSIGN_MODAL_DESCRIPTION = 'You are about to un-assign ISBNs from Automatic Printers deliveries. All previous assignments will be deleted.';

export const SUCCESS_ASSIGN = 'Assignments were updated successfully';

export const WARNING_MODAL_TITLE = 'Selection on this screen for automatic delivery will not be saved if you close this window.';
