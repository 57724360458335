import { AnyAction } from 'redux';
import _ from 'lodash';

import { SUCCESS, FAILURE } from 'src/redux/asyncMiddleware';

import { InferActionTypes } from 'src/redux/utils';
import * as actions from './actions';
import {
  SET_NETWORK,
  REMOVE_NETWORK,
  NetworkState,
  RequestInfo,
  IsRequestInfoPayload,
} from './types';

export * from './actions';
export * from './types';

type ActionTypes = InferActionTypes<typeof actions>;

export const initialState: NetworkState = null;

export const isSetRequestAction = (
  arg: ReturnType<typeof actions.setRequestInfo> | AnyAction,
): arg is ReturnType<typeof actions.setRequestInfo> => arg.type === SET_NETWORK || (
  arg.url
  && ![SET_NETWORK, REMOVE_NETWORK].includes(arg.type)
);

export default (
  state: NetworkState = initialState,
  action: ActionTypes,
): NetworkState => {
  let newState = state;

  if (isSetRequestAction(action)) {
    /* eslint-disable no-case-declarations */
    const payload: RequestInfo = {
      status: (IsRequestInfoPayload(action.payload) && action.payload?.status)
        || (action.type.includes(SUCCESS) && 'success')
        || (action.type.includes(FAILURE) && 'failure')
        || 'fetching',
    };
    /* eslint-enable */

    if (payload.status === 'failure') {
      payload.error = IsRequestInfoPayload(action.payload)
        ? action.payload.error
        : action.payload;
    }

    newState = Object.assign(
      {},
      state,
      { [action.url]: payload },
    );
  }
  else if (
    action.type === REMOVE_NETWORK
    && (!!state && action.url in state!)
  ) {
    newState = _.omit(state, action.url);
  }

  return _.isEmpty(newState) ? null : newState;
};
