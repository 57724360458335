export const SET_NETWORK = 'SET_NETWORK';
export const REMOVE_NETWORK = 'REMOVE_NETWORK';

export type RequestStatus = 'fetching' | 'success' | 'failure';
export interface RequestInfo { status: RequestStatus; error?: string }
export type NetworkState = Record<string, RequestInfo> | null;

export const IsRequestInfoPayload = (arg?: RequestInfo | string): arg is RequestInfo => (
  typeof arg === 'object' && 'status' in arg
);
