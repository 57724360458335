import axiosInstance from './services/http';

const TIMEOUT_FOR_MULTIPLE_REQUESTS = 500;

let timeoutForClassPresence: NodeJS.Timer;

const handleResponse = () => {
  timeoutForClassPresence = setTimeout(() => {
    document.body.classList.remove('fetch-data-on');
  }, TIMEOUT_FOR_MULTIPLE_REQUESTS);
};

axiosInstance.interceptors.request.use(config => {
  if (timeoutForClassPresence) {
    clearTimeout(timeoutForClassPresence);
  }

  document.body.classList.add('fetch-data-on');

  return config;
});

axiosInstance.interceptors.response.use(
  res => {
    handleResponse();

    return Promise.resolve(res);
  },
  err => {
    handleResponse();

    return Promise.reject(err);
  },
);
