import React, { InputHTMLAttributes, memo } from 'react';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Printers } from 'src/redux/reducers/printers';
import { TraditionalValue } from '../common/Interfaces';
import { TRADITIONAL_TYPE, TRADITIONAL_TITLE, TRADITIONAL_SELECT_BUTTON } from '../common/constants';

interface ITraditional {
  printers: Printers
  value:TraditionalValue,
  onChange:(value:TraditionalValue) => void;
}

export const getTraditional = (printers: Printers, defaultValue: boolean = false): TraditionalValue => printers
  .filter(printer => printer.printerTypeName === TRADITIONAL_TYPE)
  .reduce((acc, printer) => ({ ...acc, [printer.id]: defaultValue }), {});

const isAllSelected = (value: TraditionalValue):boolean => !Object.values(value).some(flag => !flag);

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const Traditional = ({ printers, value, onChange }:ITraditional) => {
  const { title } = useStyles();

  const onSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, [event.target.value]: event.target.checked });
  };

  const onSelectAll = () => {
    const flag = !isAllSelected(value);
    onChange(_.mapValues(value, () => flag));
  };

  return (
    <Box
      display="flex" flexDirection="column" width={150} marginTop={2}
      marginRight={2}
    >
      <Typography variant="body1" gutterBottom className={title}>
        {TRADITIONAL_TITLE}
      </Typography>
      {Object.keys(value).length > 0 && (
        <Button
          disableElevation
          color="primary"
          size="small"
          variant="contained"
          data-seleniumid="select-all-button"
          onClick={onSelectAll}
        >
          {TRADITIONAL_SELECT_BUTTON}
        </Button>
      ) }
      {Object.entries(value).map(([id, isSelected]) => {
        const name = _.find(printers, { id })?.printerName || '';
        return (
          <FormControlLabel
            key={id}
            control={(
              <Checkbox
                checked={isSelected}
                onChange={onSelect}
                value={id}
                color="primary"
                inputProps={{ 'data-seleniumid': `${name.replace(' ', '-').toLowerCase()}-checkbox` } as InputHTMLAttributes<HTMLInputElement>}
              />
            )}
            label={name}
          />
        );
      })}
    </Box>
  );
};

export default memo(Traditional);
