import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import BaseLayout from 'src/components/layout/BaseLayout';
import { ITheme } from 'src/App';
import { isIframeMode } from 'src/gate';
import { sendToVault, getURLParam } from 'src/utils';
import {
  ISBN_KEY,
  REDIRECT_TO,
  VAULT_ROUTE_PD_ASSIGNMENT,
} from 'src/constants';
import paths from 'src/router/paths';
import { loadPrintersAssignments, getPrinters } from 'src/redux/reducers/printersAssignments';
import useRequest from 'src/utils/hooks/useRequest';
import {
  TITLE,
  TRADITIONAL_TITLE,
  POD_TITLE,
  NOTE,
  NOTE_LINK,
} from './common/constants';
import { createVendorsRows, columns } from './common/tablesConfiguration';
import VendorsSection from './components/VendorsSection';

const useStyles = makeStyles((theme: ITheme) => ({
  vendorsWrap: {
    display: 'flex',
    margin: `${theme.spacing(1)}px 0`,
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(5),
    },
  },
  noteLink: {
    textTransform: 'uppercase',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const useTitleStyle = makeStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

const AssignmentsStatus = () => {
  const location = useLocation();
  const history = useHistory();
  const isbn = getURLParam(location.search, ISBN_KEY);
  const printers = useSelector(getPrinters);
  const items = createVendorsRows(printers || []);
  const classes = useStyles();
  const titleClasses = useTitleStyle();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isbn) {
      dispatch(loadPrintersAssignments(isbn));
    }
  }, []);

  const openPrinterAssignment = () => {
    if (isIframeMode()) {
      sendToVault(REDIRECT_TO, VAULT_ROUTE_PD_ASSIGNMENT);
    }
    else {
      const params = isbn ? `?${qs.stringify({ isbn })}` : '';
      history.push(`${paths.assignments}${params}`);
    }
  };

  const getPrintersRequest = useRequest({ route: { endpoint: 'printerAssignments', params: { isbn }, paramType: 'urlGetParams' } });
  const isLoading = !!isbn && (!getPrintersRequest || getPrintersRequest?.status === 'fetching');

  const columnsConfig = useMemo(() => columns, []);
  const traditionalItems = useMemo(() => _.filter(items, ['type', 'TRADITIONAL']), [items]);
  const podItems = useMemo(() => _.filter(items, ['type', 'POD']), [items]);

  return (
    <BaseLayout>
      <Typography
        variant="h6"
        classes={titleClasses}
        data-seleniumid="page-title"
      >
        {TITLE}
      </Typography>
      <div className={classes.vendorsWrap}>
        <VendorsSection
          isLoading={isLoading}
          seleniumID="traditional"
          title={TRADITIONAL_TITLE}
          columns={columnsConfig}
          items={traditionalItems}
        />
        <VendorsSection
          isLoading={isLoading}
          seleniumID="pod"
          title={POD_TITLE}
          columns={columnsConfig}
          items={podItems}
        />
      </div>
      <Typography variant="body2" data-seleniumid="note">
        {NOTE}<span data-seleniumid="note-link" className={classes.noteLink} onClick={openPrinterAssignment}>{NOTE_LINK}</span>
      </Typography>
    </BaseLayout>
  );
};

export default AssignmentsStatus;
