import React from 'react';
import { useLocation } from 'react-router';
import { Typography, Box } from '@material-ui/core';
import paths from 'src/router/paths';

const LOADING_MSG = 'Loading, please wait';

const ignorePath = Object.values(paths);

const NotFound = () => {
  const { pathname } = useLocation();

  if (ignorePath.some(path => pathname.startsWith(path))) {
    return (
      <Box display="flex" margin={1}>
        <Typography variant="subtitle1" align="center">
          {LOADING_MSG}
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography variant="body1" align="center">
        Page not found.
      </Typography>
    </Box>
  );
};

export default NotFound;
