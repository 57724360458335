import { FAILED_ACTION_MESSAGE } from 'src/constants';
import { ErrorResponse } from 'src/services/api';
import { toastrErrorWithoutAuth } from 'src/utils';
import { getPrintersAssignments } from 'src/redux/api';
import {
  LOAD_PRINTERS_ASSIGNMENTS,
  LOAD_PRINTERS_ASSIGNMENTS_SUCCESS,
  PrintersAssignment,
  PrintersAssignmentResponse,
} from './types';

export const loadPrintersAssignments = (isbn: string) => ({
  type: LOAD_PRINTERS_ASSIGNMENTS,
  transformError: (e: ErrorResponse) => {
    toastrErrorWithoutAuth(e, FAILED_ACTION_MESSAGE('load printers assignments'));
    return e;
  },
  transformResult: (res: PrintersAssignmentResponse) => (res?.printers?.length ? res.printers : []),
  asyncCall: () => getPrintersAssignments(isbn),
} as const);

export const setPrinters = (printers: PrintersAssignment) => ({
  type: LOAD_PRINTERS_ASSIGNMENTS_SUCCESS,
  payload: printers,
} as const);
